export const SET_LOADING = "SET_LOADING";

export const GET_TODOS_START = "GET_TODOS_START";
export const GET_TODOS_SUCCEEDED = "GET_TODOS_SUCCEEDED";
export const GET_TODOS_FAILED = "GET_TODOS_FAILED";

export const SET_TODO_START = "SET_TODO_START";
export const SET_TODO_SUCCEEDED = "SET_TODO_SUCCEEDED";
export const SET_TODO_FAILED = "SET_TODO_FAILED";

export const EDIT_TODO_START = "EDIT_TODO_START";
export const EDIT_TODO_SUCCEEDED = "EDIT_TODO_SUCCEEDED";
export const EDIT_TODO_FAILED = "EDIT_TODO_FAILED";

export const DELETE_TODO_START = "DELETE_TODO_START";
export const DELETE_TODO_SUCCEEDED = "DELETE_TODO_SUCCEEDED";
export const DELETE_TODO_FAILED = "DELETE_TODO_FAILED";

export const COMPLETE_TODO_START = "COMPLETE_TODO_START";
export const COMPLETE_TODO_SUCCEEDED = "COMPLETE_TODO_SUCCEEDED";
export const COMPLETE_TODO_FAILED = "COMPLETE_TODO_FAILED";

export default {
  SET_LOADING,
  GET_TODOS_START,
  GET_TODOS_SUCCEEDED,
  GET_TODOS_FAILED,
  SET_TODO_START,
  SET_TODO_SUCCEEDED,
  SET_TODO_FAILED,
  EDIT_TODO_START,
  EDIT_TODO_SUCCEEDED,
  EDIT_TODO_FAILED,
  DELETE_TODO_START,
  DELETE_TODO_SUCCEEDED,
  DELETE_TODO_FAILED,
  COMPLETE_TODO_START,
  COMPLETE_TODO_SUCCEEDED,
  COMPLETE_TODO_FAILED,
};
